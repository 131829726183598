import { ISiteMembersApi } from 'feature-site-members'
import { LoginSocialBarFeatureProps } from '@wix/thunderbolt-components'

type LoginSocialBarMemberDetails = Pick<LoginSocialBarFeatureProps, 'isLoggedIn' | 'userName' | 'avatarUri'>

export const resolveMemberDetails = async (siteMembersApi: ISiteMembersApi): Promise<LoginSocialBarMemberDetails> => {
	const memberDetails = await siteMembersApi.getMemberDetails()

	if (!memberDetails) {
		return {
			isLoggedIn: false,
			userName: '',
			avatarUri: '',
		}
	}

	const userName = memberDetails.nickname || memberDetails.memberName || memberDetails.loginEmail

	return {
		isLoggedIn: true,
		userName,
		avatarUri: memberDetails.imageUrl,
	}
}
